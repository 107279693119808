import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Layout.module.scss';
import Tabs from 'components/Tabs';
import Menu from 'components/Menu';
import { metaKeywords } from 'utils/constants';
import { routes } from 'data/routes';

export default function Layout({ seo, children, section, data, pid }) {
    const myMenu = useRef();
    const myHTML = useRef();
    const [menuIsActive, setMenuIsActive] = useState(false);

    useEffect(() => {
        myMenu.current = document.getElementById('mySidenav');
        myHTML.current = document.documentElement;
        myMenu.current.addEventListener('transitionend', OnTransitionEnd, false);
    }, [menuIsActive]);

    const handleOnToggleMenu = () => {
        myMenu.current.classList.add('animatable');
        myMenu.current.classList.toggle('sidebarMenuOpened');
        myHTML.current.classList.toggle('noScroll');

        setMenuIsActive(!menuIsActive);
    };

    const handleHideMenu = () => {
        myMenu.current.classList.add('animatable');
        myMenu.current.classList.remove('sidebarMenuOpened');
        myHTML.current.classList.remove('noScroll');
        setMenuIsActive(false);
    };

    const OnTransitionEnd = () => {
        myMenu.current.classList.remove('animatable');
    };

    return (
        <div className={`${Styles.layout} ${Styles[section]} has-background-primary`}>
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
                <title>{seo.title || 'Bruno Domingos - Portfolio'}</title>
                <meta name="keywords" content={metaKeywords} />
                <meta
                    name="description"
                    content={seo.description || 'Bruno Domingos - Portfolio'}
                />
                <meta name="author" content="Bruno Domingos" />
            </Head>

            <Tabs active={section} onToggleMenu={handleOnToggleMenu} isMenuActive={menuIsActive} />
            <Menu active={section} data={routes} pid={pid} onOpenTab={handleHideMenu} />

            <main id="main" className={`${Styles.main}`} onClick={handleHideMenu}>
                {children}
            </main>
        </div>
    );
}

Layout.propTypes = {
    seo: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    section: PropTypes.string,
    route: PropTypes.string,
    pid: PropTypes.string,
    data: PropTypes.array,
};
