// import { useState } from 'react';

// export function useLocalStorage(key, initialValue) {
//     // State to store our value
//     // Pass initial state function to useState so logic is only executed once
//     const [storedValue, setStoredValue] = useState(() => {
//         try {
//             if (typeof window === 'undefined') {
//                 return initialValue;
//             }
//             // Get from local storage by key
//             const item = window.localStorage.getItem(key);
//             // Parse stored json or if none return initialValue
//             return item ? JSON.parse(item) : initialValue;
//         } catch (error) {
//             // If error also return initialValue
//             console.log('useLocalStorage 1:', error);
//             return initialValue;
//         }
//     });

//     // Return a wrapped version of useState's setter function that ...
//     // ... persists the new value to localStorage.
//     const setValue = value => {
//         try {
//             if (typeof window == 'undefined') {
//                 console.warn(
//                     `Tried setting localStorage key “${key}” even though environment is not a client`,
//                 );
//             }

//             // Allow value to be a function so we have same API as useState
//             const valueToStore = value instanceof Function ? value(storedValue) : value;
//             // Save state
//             setStoredValue(valueToStore);
//             // Save to local storage
//             window.localStorage.setItem(key, JSON.stringify(valueToStore));
//         } catch (error) {
//             // A more advanced implementation would handle the error case
//             console.log('useLocalStorage 2:', error);
//         }
//     };

//     return [storedValue, setValue];
// }

import { useCallback, useEffect, useState } from 'react';

export function useLocalStorage(key, initialValue) {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback(() => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(`1 - Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    }, [initialValue, key]);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue);
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value, dispatch = true) => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window == 'undefined') {
            console.warn(
                `Tried setting localStorage key “${key}” even though environment is not a client`,
            );
        }

        try {
            // Allow value to be a function so we have the same API as useState
            const newValue = value instanceof Function ? value(storedValue) : value;
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(newValue));
            // Save state
            setStoredValue(newValue);
            // We dispatch a custom event so every useLocalStorage hook are notified
            if (dispatch) window.dispatchEvent(new Event('local-storage'));
        } catch (error) {
            console.warn(`2 - Error setting localStorage key “${key}”:`, error);
        }
    };

    useEffect(() => {
        setStoredValue(readValue());
    }, [readValue]);

    useEffect(() => {
        const handleStorageChange = () => {
            setStoredValue(readValue());
        };
        // this only works for other documents, not the current one
        window.addEventListener('storage', handleStorageChange);
        // this is a custom event, triggered in writeValueToLocalStorage
        window.addEventListener('local-storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('local-storage', handleStorageChange);
        };
    }, [readValue]);

    return [storedValue, setValue];
}
