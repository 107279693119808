export const tagsName = {
    JAVASCRIPT: 'Javascript',
    HTML: 'HTML',
    CSS: 'CSS',
    PWA: 'PWA',
    REACT: 'React',
    REACT_NATIVE: 'React Native',
    IONIC: 'Ionic Framework',
    REDUX: 'Redux',
    SASS: 'SASS',
    NEXT: 'Next.js',
    PHP: 'PHP',
    LARAVEL: 'Laravel',
    MYSQL: 'MySQL',
    LUMEN: 'Lumen',
    REDIS: 'Redis',
    JQUERY: 'jQuery',
    JQUERY_MOBILE: 'jQuery Mobile',
    BOOTSTRAP: 'Bootstrap',
    BULMA: 'Bulma',
    WEBPACK: 'Webpack',
    SPA: 'SPA',
};

export const metaKeywords = `bruno,domingos,bruno domingos,web,development,web development,mobile,mobile development,frontend,developer,${Object.values(
    tagsName,
)
    .join()
    .toLowerCase()}`;

export const defaultTabs = [
    {
        text: 'Home',
        slug: '/',
    },
    {
        text: 'Portfolio',
        slug: '/portfolio',
    },
    {
        text: 'About',
        slug: '/about',
    },
    {
        text: 'Contact',
        slug: '/contact',
    },
];
