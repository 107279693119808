import Link from 'next/link';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { isElementIntoViewHorizontal } from 'utils/functions';
import Styles from './Tabs.module.scss';
import { defaultTabs } from 'utils/constants';

export default function Tabs({ data, active, onToggleMenu, isMenuActive }) {
    const router = useRouter();
    const [tabs, setTabs] = useLocalStorage('tabs', null);
    const myRef = useRef(null);
    const activeSlug = `/${active}`;

    useEffect(() => {
        if (!tabs || tabs.length === 0) {
            setTabs(defaultTabs);
        }

        setTimeout(() => {
            isElementIntoViewHorizontal(myRef.current, true, 50, {
                behavior: 'auto',
                block: 'end',
                inline: 'nearest',
            });
        }, 0);
    }, [setTabs, tabs]);

    const handleCloseTab = e => {
        e.preventDefault();
        e.stopPropagation();

        const slug = e.currentTarget.getAttribute('data-slug');

        const index = tabs.findIndex(element => {
            if (element.slug === slug) {
                return true;
            }
        });
        const prev = tabs[index - 1];
        const newTabs = tabs.filter(tab => tab.slug !== slug);

        if (activeSlug === slug && slug.indexOf(`/portfolio/${active}`) === -1) {
            router.replace(prev.slug);
        }

        if (newTabs.length > 0) {
            setTabs(newTabs, false);
        }
    };

    const handleOnReset = () => {
        setTabs(defaultTabs);
        router.replace('/');
    };

    return (
        <nav className={`tabs is-boxed is-medium has-background-primary-dark mb-0 ${Styles.tabs}`}>
            <button
                className={`button is-ghost has-background-primary-dark ${Styles.menuBtn}`}
                onClick={onToggleMenu}
                aria-label="menu"
            >
                <span className={`icon ${Styles.menu}`}>
                    {/* <i className={isMenuActive ? 'fas fa-folder-open' : 'fas fa-folder'}></i> */}
                    {isMenuActive && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            // className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                            />
                        </svg>
                    )}
                    {!isMenuActive && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            // className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                            />
                        </svg>
                    )}
                </span>
            </button>

            {tabs && tabs.length > 0 && (
                <ul>
                    {tabs.map(tab => (
                        <li
                            key={tab.slug}
                            ref={activeSlug === tab.slug ? myRef : null}
                            className={activeSlug === tab.slug ? 'is-active' : ''}
                        >
                            <Link href={tab.slug}>
                                <a>
                                    {tab.text}

                                    {tabs.length > 1 && (
                                        <span
                                            data-slug={tab.slug}
                                            className={`icon is-small ${Styles.close}`}
                                            onClick={handleCloseTab}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                </a>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}

            <button
                className={`button is-ghost has-background-primary-dark ${Styles.resetBtn}`}
                onClick={handleOnReset}
                aria-label="reset"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            </button>
        </nav>
    );
}

Tabs.propTypes = {
    data: PropTypes.array,
    active: PropTypes.string,
    onToggleMenu: PropTypes.func,
    isMenuActive: PropTypes.bool,
};
