import Link from 'next/link';
import PropTypes from 'prop-types';
import Styles from './Menu.module.scss';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { defaultTabs } from 'utils/constants';

export default function Menu({ data, active, pid, onOpenTab }) {
    const [tabs, setTabs] = useLocalStorage('tabs', null);
    const myRef = useRef(null);

    useEffect(() => {
        if (!tabs || tabs.length === 0) {
            setTabs(defaultTabs);
        }
        myRef?.current && myRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
    }, [setTabs, tabs]);

    const handleOpenNewTab = e => {
        const newTab = JSON.parse(e.currentTarget.getAttribute('data-tab'));
        const search = tabs.filter(tab => tab.slug === newTab.slug);

        if (!search || search.length === 0) {
            const newTabs = [...tabs, newTab];
            setTabs(newTabs);
            onOpenTab && typeof onOpenTab === 'function' && onOpenTab();
        }
    };

    return (
        <aside id="mySidenav" className={`menu ${Styles.menu} pl-3`}>
            <ul className="menu-list">
                <li>
                    <p className="menu-label">Home</p>
                    <ul>
                        <li
                            onClick={handleOpenNewTab}
                            data-tab={JSON.stringify({ text: 'Home', slug: '/' })}
                        >
                            <Link href="/">
                                <a
                                    ref={active === 'home' ? myRef : null}
                                    className={active && active === 'home' ? 'is-active' : ''}
                                >
                                    index.js
                                </a>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <p className="menu-label">Portfolio</p>
                    <ul className="pr-0 mr-0">
                        <li
                            onClick={handleOpenNewTab}
                            data-tab={JSON.stringify({ text: 'Portfolio', slug: '/portfolio' })}
                        >
                            <Link href="/portfolio">
                                <a
                                    ref={!pid && active === 'portfolio' ? myRef : null}
                                    className={
                                        !pid && active && active === 'portfolio' ? 'is-active' : ''
                                    }
                                >
                                    index.js
                                </a>
                            </Link>
                        </li>
                        <>
                            {data && data.length > 0 && (
                                <li>
                                    <p className="menu-label mt-3 ml-3">Projects</p>
                                    <ul className="pr-0 mr-0">
                                        {data.map(route => (
                                            <li
                                                key={route.slug}
                                                onClick={handleOpenNewTab}
                                                data-tab={JSON.stringify({
                                                    text: route.title,
                                                    slug: `/portfolio/${route.slug}`,
                                                })}
                                            >
                                                <Link href={`/portfolio/${route.slug}`}>
                                                    <a
                                                        ref={
                                                            pid && pid === route.slug ? myRef : null
                                                        }
                                                        className={
                                                            pid && pid === route.slug
                                                                ? 'is-active'
                                                                : ''
                                                        }
                                                    >{`${route.slug}.js`}</a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            )}
                        </>
                    </ul>
                </li>
                <li>
                    <p className="menu-label">About</p>
                    <ul>
                        <li
                            onClick={handleOpenNewTab}
                            data-tab={JSON.stringify({ text: 'About', slug: '/about' })}
                        >
                            <Link href="/about">
                                <a
                                    ref={active === 'about' ? myRef : null}
                                    className={active && active === 'about' ? 'is-active' : ''}
                                >
                                    index.js
                                </a>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <p className="menu-label">Contact</p>
                    <ul>
                        <li
                            onClick={handleOpenNewTab}
                            data-tab={JSON.stringify({ text: 'Contact', slug: '/contact' })}
                        >
                            <Link href="/contact">
                                <a
                                    ref={active === 'contact' ? myRef : null}
                                    className={active && active === 'contact' ? 'is-active' : ''}
                                >
                                    index.js
                                </a>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </aside>
    );
}

Menu.propTypes = {
    data: PropTypes.array,
    active: PropTypes.string,
    pid: PropTypes.string,
    onOpenTab: PropTypes.func,
};
