// import { projects } from './projects';
import { projects } from '@garlink/bd-pkg-portfolio';

export const routes = projects.map(p => {
    return {
        id: p.id,
        slug: p.slug,
        title: p.title,
    };
});
