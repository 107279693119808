import { projects, tags } from '@garlink/bd-pkg-portfolio';

export const formatedTags = arr => {
    if (arr) {
        return arr.map(t => {
            if (!t?.name || !t?.slug) {
                return `<a class='has-text-warning' href='/'}'>ERROR</a>`;
            }
            return `<a class='has-text-warning' href='${t?.slug ? `/tags/${t.slug}` : '/'}'}'>${
                t.name
            }</a>`;
        });
    }
    return tags.map(t => {
        return `<a class='has-text-warning' href='/tags/${t.slug}'>${t.name}</a>`;
    });
};

export const formatedTagsByProject = project => {
    const projectTags = project.tags.map(t => tags.filter(i => i.name === t)[0]);
    return formatedTags(projectTags);
};

export const formatedTagsByNames = arr => {
    const projectTags = arr.map(t => tags.filter(i => i.name === t)[0]);
    return formatedTags(projectTags);
};

export const getFormatedProjects = arr => {
    let output = [];

    if (arr) {
        output = arr.map(p => {
            return formatedProject(p);
        });
    } else {
        output = projects.map(p => {
            return formatedProject(p);
        });
    }

    return [...output, { total: `<span class='has-text-danger'>${output.length}</span>` }];
    // return output;
};

export const formatedProject = p => {
    const entry = {
        title: p.title,
        subtitle: p.subtitle,
        url: p.slug,
        client: p.client,
        created_at: p.date,
        motivation: p.motivation,
        description: p.description,
        production_url: p?.url ? p.url : null,
        tags: formatedTagsByNames(p.tags),
    };

    Object.entries(entry).forEach(([key, value]) => {
        switch (key) {
            case 'title':
                entry[key] = `<span class='has-text-success'>${stripHtmlTags(value)}</span>`;
                break;
            case 'subtitle':
            case 'client':
            case 'created_at':
            case 'date':
            case 'motivation':
            case 'description':
                entry[key] = `<span class='has-text-info'>${stripHtmlTags(value)}</span>`;
                break;

            case 'url':
                entry[key] = value
                    ? `<a class='has-text-link' href='/portfolio/${p.slug}'>/portfolio/${p.slug}</a>`
                    : `<span class='has-text-danger'>null</span>`;
                entry.production_url = value
                    ? `<a class='has-text-link' target="_blank" rel='noopener noreferrer' href='${value}'>${value}</a>`
                    : `<span class='has-text-danger'>null</span>`;
                break;

            case 'production_url':
                entry[key] = value
                    ? `<a class='has-text-link' target="_blank" rel='noopener noreferrer' href='${value}'>${value}</a>`
                    : `<span class='has-text-danger'>null</span>`;
                break;

            default:
                break;
        }
    });

    return entry;
};

export const stripHtmlTags = string => {
    return string.replace(/(<([^>]+)>)/gi, '');
};

export const isElementIntoView = (el, scroll, margin = 0) => {
    if (!el) return null;

    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = elemTop + margin >= 0 && elemBottom + margin <= window.innerHeight;
    if (scroll && !isVisible) {
        el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
};

export const isElementIntoViewVertical = (el, scroll, margin = 0, settings = false) => {
    if (!el) return null;

    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = elemTop + margin >= 0 && elemBottom + margin <= window.innerHeight;
    if (scroll) {
        el.scrollIntoView(
            settings ? settings : { behavior: 'smooth', block: 'end', inline: 'nearest' },
        );
    }

    return isVisible;
};

export const isElementIntoViewHorizontal = (el, scroll, margin = 0, settings = false) => {
    if (!el) return null;

    const rect = el.getBoundingClientRect();
    const elemLeft = rect.left;
    const elemRight = rect.right;

    // Only completely visible elements return true:
    const isVisible = elemLeft + margin >= 0 && elemRight + margin <= window.innerWidth;
    if (scroll && !isVisible) {
        el.scrollIntoView(
            settings ? settings : { behavior: 'smooth', block: 'end', inline: 'nearest' },
        );
    }

    return isVisible;
};
